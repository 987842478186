import { useQuery } from "@tanstack/react-query";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { HappyEvent } from "../../../types/Event";
import { format } from "date-fns";
import { Button } from "../../../components/reusable/Button";
import { classNames } from "../../../utils/styles";
import { EventDetails } from "./EventDetails";
import { Tiers } from "./Tiers";
import { CameraIcon, PencilIcon } from "@heroicons/react/20/solid";
import DatePicker from "react-datepicker";
import { Tickets } from "./Tickets";
import { useVendor } from "../../../providers/VendorContext";
import { Sections } from "./Sections";
import { PriceLevels } from "./PriceLevels";
import { Scanners } from "./Scanners";
import Reports from "./Reports";
import { Modal } from "../../../components/reusable/Modal";
import { useCallback, useEffect, useState } from "react";

import {
  Field,
  FieldInputProps,
  Form,
  Formik,
  FormikProps,
  FormikValues,
  useFormikContext,
} from "formik";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  LinkIcon,
  ArrowDownTrayIcon
} from "@heroicons/react/24/outline";
import { Venue } from "../../../types/Venue";
import { dataURLtoBlob } from "../../../utils/images";
import { useNotification } from "../../../providers/NotificationContext";
import { Orders } from "./Orders";
import {
  CalendarIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from "@heroicons/react/24/solid";
import { BuildingStorefrontIcon } from "@heroicons/react/24/solid";
import { uniqBy } from "lodash";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { PromoterCodes } from "./PromoterCodes";
import { Spinner } from "../../../components/reusable/Spinner";
import { GuestList } from "./GuestList";

export type EventFormValues = {
  name: string;
  start_date: Date;
  end_date: Date;
  description: string;
  refund_policy: string;
  additional_terms: string;
  max_tickets_per_person: number | null;
  min_time_between_scans: number | null;
  open_to_public: boolean;
  allow_multiple_scans: boolean;
  primary_venue_id: number | null;
  logo: Blob | null;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip: string;
  [key: string]: string | number | boolean | Date | null | Blob;
};

export const ShowEvent = () => {
  const params = useParams();
  const [editingEvent, setEditingEvent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentOrganization } = useVendor();
  const { showSuccess, showError } = useNotification();
  const { data: orderData } = useQuery({
    queryKey: ["orders", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders`,
        params: {
          event_id: params.id,
          organization_id: currentOrganization?.id,
        },
      });
    },
    enabled: !!currentOrganization,
  });
  const { data, refetch } = useQuery({
    queryKey: ["event", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/events/${params.id}`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!(params && params.id) && !!currentOrganization,
  });
  const handleFormSubmit = (payload: EventFormValues) => {
    const formData = new FormData();
    formData.append("event[name]", payload.name || "");
    formData.append("event[start_date]", payload.start_date.toString() || "");
    formData.append("event[end_date]", payload.end_date.toString() || "");
    formData.append("event[description]", payload.description || "");
    formData.append("event[refund_policy]", payload.refund_policy || "");
    formData.append("event[additional_terms]", payload.additional_terms || "");
    formData.append(
      "event[max_tickets_per_person]",
      `${payload.max_tickets_per_person || ""}`
    );
    formData.append(
      "event[min_time_between_scans]",
      `${payload.min_time_between_scans || ""}`
    );
    formData.append("event[open_to_public]", `${payload.open_to_public}`);
    formData.append(
      "event[allow_multiple_scans]",
      `${payload.allow_multiple_scans}`
    );
    if (payload.primary_venue_id) {
      formData.append("event[primary_venue_id]", `${payload.primary_venue_id}`);
    }
    formData.append("event[address_1]", payload.address_1 || "");
    formData.append("event[address_2]", payload.address_2 || "");
    formData.append("event[city]", payload.city || "");
    formData.append("event[state]", payload.state || "");
    formData.append("event[zip]", payload.zip || "");
    if (payload.logo) {
      console.log("payload.logo", payload.logo);
      formData.append("event[logo]", payload.logo || "");
    }

    makeApiRequest({
      path: `/vendor/events/${params.id}?organization_id=${currentOrganization?.id}`,
      method: "PUT",
      hasFormData: true,
      params: formData,
    }).then((res) => {
      if (res.status === 200) {
        setEditingEvent(false);
        showSuccess("Event successfully updated.");
        refetch();
      }
    })
    .catch((err) => {
      const message = err.response.data.message;
      showError(message);
    });
  };
  const event: HappyEvent = data?.data;
  const orders = orderData?.data;

  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(
        `${import.meta.env.VITE_CONSUMER_WEB_URL}/events/${event?.slug}`
      )
      .then(() => {
        showSuccess("Event URL successfully copied to clipboard.");
      })
      .catch((err) => {
        // Failed to copy
        console.error("Failed to copy text: ", err);
      });
  }, [event]);

  const updateIsOpenToPublic = (open: boolean) => {
    setIsLoading(true);
    makeApiRequest({
      path: `/vendor/events/${event.id}`,
      method: "PUT",
      params: {
        event: { open_to_public: open },
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess(
            open
              ? "Event is now open to the public."
              : "Event is now closed to the public."
          );
          refetch();
        }
      })
      .catch((err) => {
        const message = err.response.data.message;
        showError(message);
      })
      .finally(() => setIsLoading(false));
  };

  const downloadEventSummary = (event: HappyEvent) => {
    makeApiRequest({
      path: `/vendor/events/${event.id}/event_summary`,
      method: "GET",
      responseType: 'blob',
      params: { organization_id: currentOrganization?.id },
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${event.name}_summary.pdf`);
        document.body.appendChild(link);
        link.click();
    });
  }

  if (event) {
    return (
      <>
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="flex gap-4">
            <img
              src={
                event.image_url ||
                "https://lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-black.svg"
              }
              alt="event"
              className="w-52 h-auto rounded-md"
            />
            <div className="flex flex-col gap-1">
              <h1 className="text-2xl font-bold text-gray-500 flex items-center gap-2">
                {event.name}{" "}
                {event.open_to_public ? (
                  <button
                    onClick={() => updateIsOpenToPublic(false)}
                    className="px-2 py-1 bg-lightPurple/30 text-lightPurple rounded text-xs"
                  >
                    Open
                  </button>
                ) : (
                  <button
                    onClick={() => updateIsOpenToPublic(true)}
                    className="px-2 py-1 bg-yellow/30 text-yellow rounded text-xs"
                  >
                    Closed
                  </button>
                )}
                {isLoading && <Spinner size="sm" />}
              </h1>
              <p className="mb-3 text-sm">{event.truncated_description}</p>
              <span className="flex gap-2 items-center">
                <CalendarIcon className="w-5" />
                <span>
                  {event.start} - {event.end}
                </span>
              </span>
              <span className="flex gap-2 items-center">
                <BuildingStorefrontIcon className="w-5" />
                <span>{event.primary_venue?.name}</span>
              </span>
              <span className="flex gap-2 items-center">
                <MapPinIcon className="w-5" />
                <span>
                  {event.primary_venue?.city}, {event.primary_venue?.state}
                </span>
              </span>
            </div>
          </div>
          <div className="flex gap-2">
            <Menu as="div" className={"relative"}>
              <MenuButton as="div">
                <Button className="flex items-center gap-3 text-sm font-bold">
                  Actions
                  <ChevronDownIcon className="w-4" />
                </Button>
              </MenuButton>
              <MenuItems
                className={
                  "absolute z-30 bg-white right-0 w-44 rounded-lg mt-2"
                }
                >
                <MenuItem>
                  {({ focus }) => (
                    <button
                      onClick={() => setEditingEvent(true)}
                      className={`hover:bg-gray-50 group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      <PencilIcon className="w-4 h-4 mr-2" />
                      Edit Event
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <button
                      onClick={copyToClipboard}
                      className={`hover:bg-gray-50 group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      <LinkIcon className="w-4 h-4 mr-2" />
                      Copy Public Url
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <button
                      onClick={() => downloadEventSummary(event)}
                      className={`hover:bg-gray-50 group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      <ArrowDownTrayIcon className="w-4 h-4 mr-2" />
                      Download Summary
                    </button>
                  )}
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
        <EventTabs />

        <Routes>
          <Route
            path="/"
            element={<EventDetails event={event} orders={orders} />}
          />
          <Route path="tiers" element={<Tiers event={event} />} />
          <Route path="orders" element={<Orders orders={orders} />} />
          <Route path="sections" element={<Sections event={event} />} />
          <Route path="tickets" element={<Tickets event={event} />} />
          <Route path="price-levels" element={<PriceLevels event={event} />} />
          <Route path="scanners" element={<Scanners />} />
          <Route path="reports/*" element={<Reports />} />
          <Route
            path="promoter-codes"
            element={<PromoterCodes event={event} />}
          />
          <Route path="guest-list" element={<GuestList event={event} />} />
        </Routes>
        <Modal
          title={`Editing ${event?.name}`}
          isOpen={editingEvent}
          onRequestClose={() => setEditingEvent(false)}
        >
          <>
            <Formik
              onSubmit={(payload) => {
                handleFormSubmit(payload);
              }}
              initialValues={{
                name: event.name || "",
                start_date: event.start_date
                  ? new Date(event.start_date)
                  : new Date(),
                end_date: event.end_date
                  ? new Date(event.end_date)
                  : new Date(),
                description: event.description || "",
                refund_policy: event.refund_policy || "",
                additional_terms: event.additional_terms || "",
                max_tickets_per_person: event.max_tickets_per_person || null,
                min_time_between_scans: event.min_time_between_scans || null,
                open_to_public: event.open_to_public || false,
                allow_multiple_scans: event.allow_multiple_scans || false,
                primary_venue_id: event.primary_venue?.id || 0,
                logo: null, // Assuming logo is a file input, it's typically handled differently, but here it's set to an empty string for consistency
                address_1: event.address_1 || "",
                address_2: event.address_2 || "",
                city: event.city || "",
                state: event.state || "",
                zip: event.zip || "",
              }}
            >
              <Form className="flex flex-col gap-4 py-5">
                <h3 className="text-2xl font-bold text-gray-500 mb-4">
                  Event Details
                </h3>
                <div className="w-full flex gap-4 items-end">
                  <Field name="name">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="name">Name</label>
                        <input
                          placeholder="Event Name"
                          type="text"
                          id="name"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                  <PhotoUpload event={event} />
                </div>

                <div className="w-full">
                  <Field name="description">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2">
                        <label htmlFor="description">Description</label>
                        <textarea
                          placeholder="Event Description"
                          rows={3}
                          id="description"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="w-full">
                  <Field name="refund_policy">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2">
                        <label htmlFor="refund_policy">Refund Policy</label>
                        <textarea
                          placeholder="Start typing..."
                          rows={3}
                          id="refund_policy"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="w-full">
                  <Field name="additional_terms">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2">
                        <label htmlFor="additional_terms">Additional Terms</label>
                        <textarea
                          placeholder="Start typing..."
                          rows={3}
                          id="additional_terms"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="w-full flex gap-4 items-center">
                  <Field name="max_tickets_per_person">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="name">Max Tickets Per Person</label>
                        <input
                          placeholder="Max Tickets Per Person"
                          type="number"
                          id="max_tickets_per_person"
                          step={1}
                          max={10}
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="min_time_between_scans">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="name">Min Time Between Scans</label>
                        <input
                          placeholder="Min Time Between Scans"
                          type="number"
                          id="min_time_between_scans"
                          step={1}
                          max={1000}
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="w-full flex gap-4 items-center">
                  <Field name="start_date">
                    {({ form }: { form: FormikProps<FormikValues> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="start_date">Start Date</label>
                        <DatePicker
                          showTimeSelect
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat={"E, MMM do @ h:mm a"}
                          selected={
                            new Date(form.getFieldProps("start_date").value)
                          }
                          placeholderText="Start Date"
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                          onChange={(date) => {
                            form.setFieldValue("start_date", date as Date);
                            form.setFieldValue("end_date", date as Date);
                          }}
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="end_date">
                    {({ form }: { form: FormikProps<FormikValues> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="end_date">End Date</label>
                        <DatePicker
                          showTimeSelect
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat={"E, MMM do @ h:mm a"}
                          selected={
                            new Date(form.getFieldProps("end_date").value)
                          }
                          placeholderText="End Date"
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                          onChange={(date) =>
                            form.setFieldValue("end_date", date as Date)
                          }
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <div className="w-full flex gap-6 items-center">
                  <Field name="open_to_public">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex gap-2 items-center">
                        <input
                          checked={!!field.value}
                          type="checkbox"
                          {...field}
                        />
                        <label htmlFor="start_date">Open to Public</label>
                      </div>
                    )}
                  </Field>

                  <Field name="allow_multiple_scans">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex gap-2 items-center">
                        <input
                          checked={!!field.value}
                          type="checkbox"
                          {...field}
                        />
                        <label htmlFor="allow_multiple_scans">
                          Allow Multiple Scans
                        </label>
                      </div>
                    )}
                  </Field>
                </div>

                <PrimaryVenue event={event} />
                <h3 className="text-2xl font-bold text-gray-500 mt-4">
                  Event Address
                </h3>
                <div className="w-full flex gap-6 items-center">
                  <Field name="address_1">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="address_1">Address</label>
                        <input
                          placeholder="Address"
                          type="text"
                          id="address_1"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="address_2">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="address_2">
                          Address 2 {"(optional)"}
                        </label>
                        <input
                          placeholder="Address Line 2"
                          type="text"
                          id="address_2"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="w-full flex gap-6 items-center">
                  <Field name="city">
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <div className="flex flex-col gap-2 w-1/2">
                        <label htmlFor="city">City</label>
                        <input
                          placeholder="City"
                          type="text"
                          id="city"
                          {...field}
                          className="w-full border border-gray-300 rounded-md px-3 py-2"
                        />
                      </div>
                    )}
                  </Field>
                  <div className="flex w-1/2 gap-2">
                    <Field name="state">
                      {({ field }: { field: FieldInputProps<string> }) => (
                        <div className="flex flex-col gap-2 w-1/2">
                          <label htmlFor="state">State</label>
                          <input
                            placeholder="State"
                            type="text"
                            id="state"
                            {...field}
                            className="w-full border border-gray-300 rounded-md px-3 py-2"
                          />
                        </div>
                      )}
                    </Field>
                    <Field name="zip">
                      {({ field }: { field: FieldInputProps<string> }) => (
                        <div className="flex flex-col gap-2 w-1/2">
                          <label htmlFor="zip">Zip</label>
                          <input
                            placeholder="zip"
                            type="text"
                            id="zip"
                            {...field}
                            className="w-full border border-gray-300 rounded-md px-3 py-2"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="mt-10 flex justify-end">
                  <Button size="lg" variant="pink">
                    Save Event
                  </Button>
                </div>
              </Form>
            </Formik>
          </>
        </Modal>
      </>
    );
  }

  return null;
};

export const EventTabs = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { currentOrganization } = useVendor();
  const tabs = [
    {
      name: "Details",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}`,
      current:
        location.pathname ===
        `/vendor/organizations/${currentOrganization?.id}/events/${params.id}`,
    },
    {
      name: "Orders",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/orders`,
      current:
        location.pathname ===
        `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/orders`,
    },
    {
      name: "Tiers",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/tiers`,
      current: location.pathname.includes("tiers"),
    },
    {
      name: "Sections",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/sections`,
      current: location.pathname.includes("sections"),
    },
    {
      name: "Price Levels",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/price-levels`,
      current: location.pathname.includes("price-levels"),
    },
    {
      name: "Tickets",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/tickets`,
      current: location.pathname.includes("tickets"),
    },
    {
      name: "Scanners",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/scanners`,
      current: location.pathname.includes("scanners"),
    },
    {
      name: "Reports",
      href: `/vendor/organizations/${currentOrganization?.id}/events/${params.id}/reports/sales-by-tier`,
      current: location.pathname.includes("reports"),
    },
  ];
  return (
    <div className="mt-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          onChange={(e) => navigate(e.target.value)}
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current)?.name ?? ""}
        >
          {tabs.map((tab) => (
            <option value={tab.href} key={tab.name}>
              {tab.name}
            </option>
          ))}
          <option value={"/codes"}>Promoter Codes</option>
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={tab.href}
              className={classNames(
                tab.current
                  ? "text-lightPurple"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                // tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex items-center justify-center flex-1 overflow-hidden bg-white h-10 px-2 text-center text-xs font-medium hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </Link>
          ))}
          <Menu as="div" className="relative">
            <MenuButton className="group rounded-r-lg relative min-w-0 flex-0 overflow-hidden bg-white h-10 px-2 text-center text-xs font-medium hover:bg-gray-50 focus:z-10">
              <EllipsisHorizontalIcon className="w-6 text-gray-500" />
            </MenuButton>
            <MenuItems
              className={
                "py-1 absolute right-0 bg-gray-50 border-gray-200 border shadow-sm w-56 rounded-md"
              }
            >
              <MenuItem as="div" className={"p-3  hover:bg-gray-100"}>
                {({ close }) => (
                  <Link
                    onClick={close}
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${params.id}/promoter-codes`}
                    className="text-sm block"
                  >
                    Promoter Codes
                  </Link>
                )}
              </MenuItem>

              <MenuItem as="div" className={"p-3  hover:bg-gray-100"}>
                {({ close }) => (
                  <Link
                    onClick={close}
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${params.id}/guest-list`}
                    className="text-sm block"
                  >
                    Guest List
                  </Link>
                )}
              </MenuItem>
            </MenuItems>
          </Menu>
        </nav>
      </div>
    </div>
  );
};

const PrimaryVenue = ({ event }: { event: HappyEvent }) => {
  const formik = useFormikContext();
  const { currentOrganization } = useVendor();
  const [venueName, setVenueName] = useState<string>("");
  const { data } = useQuery({
    queryKey: ["venues", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/venues",
        params: { organization_id: currentOrganization?.id },
      }),
    enabled: !!currentOrganization,
  });
  const venues: Venue[] = data?.data;
  const { data: venueSearchResults, refetch } = useQuery({
    queryKey: ["venue_search", currentOrganization, venueName],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/venue_search",
        params: { organization_id: currentOrganization?.id, name: venueName },
      }),
    enabled: false,
  });
  const venueResults = venueSearchResults?.data;

  const removeVenue = () => {
    formik.setFieldValue("primary_venue_id", null);
  };

  const addVenue = (venue: Venue) => {
    formik.setFieldValue("primary_venue_id", venue.id);
  };

  const allVenues: Venue[] = uniqBy(venues?.concat(venueResults || []), "id");

  return (
    <div className="w-full mt-5">
      <h3 className="text-2xl font-bold text-gray-500 mb-4">Select a Venue</h3>
      {venues?.length === 0 && (
        <p>
          It looks like you have no venues attached to your org. Use the search
          below to add a venue, or skip and add a venue later.
        </p>
      )}
      <div className="flex gap-2 items-center my-6">
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              return;
            }
          }}
          className="text-lg border border-gray-300 rounded-lg w-full px-3 py-2 placeholder:text-gray-400"
          placeholder="Search Venues"
          type="text"
          value={venueName}
          onChange={(e) => setVenueName(e.target.value)}
        />
        <span
          role="button"
          className="h-12 px-12 bg-blue hover:brightness-110 text-white rounded-lg flex items-center justify-center cursor-pointer"
          onClick={() => refetch()}
        >
          Search
        </span>
      </div>
      {allVenues && allVenues.length > 0 && (
        <div className="mt-4">
          {allVenues.map((venue: Venue) => {
            const venueSelected: boolean =
              venue.id === formik.getFieldProps("primary_venue_id").value;
            return (
              <div
                key={venue.id}
                className="py-4 px-2 border-b border-gray-300 flex justify-between items-center"
              >
                <div>
                  <h4 className="font-bold">{venue.name}</h4>
                  <span className="text-gray-400">
                    {venue.city}, {venue.state}
                  </span>
                </div>

                <span
                  role="button"
                  onClick={() =>
                    venueSelected ? removeVenue() : addVenue(venue)
                  }
                  className={classNames(
                    "text-sm w-20 py-1 flex items-center justify-center rounded-lg cursor-pointer",
                    venueSelected
                      ? "bg-green-500 text-white"
                      : "bg-transparent text-gray-500 border-gray-300 border-2"
                  )}
                >
                  {venueSelected ? (
                    <CheckCircleIcon className="w-6 h-6 text-white" />
                  ) : (
                    "Add"
                  )}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const PhotoUpload = ({ event }: { event: HappyEvent }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const formik = useFormikContext();
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result;
        setImagePreview(image as string);
        const blob: Blob = dataURLtoBlob(image as string);
        formik.setFieldValue("logo", blob);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (event?.image_url) {
      setImagePreview(event.image_url);
    }
  }, []);
  return (
    <div className="w-1/2 relative h-20">
      <input
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />
      {imagePreview ? (
        <label htmlFor="file-upload" className="absolute rounded-md left-1/2 -translate-x-1/2 -translate-y-3">
          <img
            src={imagePreview}
            alt="event"
            className="border border-gray-400 p-3 cursor-pointer w-28 object-cover"
          />
        </label>
      ) : (
        <div>
          <label
            htmlFor="file-upload"
            className="hover:brightness-125 cursor-pointer  items-center flex gap-2 justify-center text-lightPurple rounded-lg"
          >
            <CameraIcon className="w-5 text-white" /> Upload a Photo
          </label>
        </div>
      )}
      {/* <p>{imageFile ? imageFile : "No file Selected"}</p> */}
    </div>
  );
};
