import * as React from "react";
import { Venue } from "../types/Venue";
import { useLocalStorage } from "@uidotdev/usehooks";

type ActionTypes =
  | "editTier"
  | "editSection"
  | "updateEvent"
  | "nextStep"
  | "updateTierWizard"
  | "prevStep"
  | "goToStep"
  | "addVenue"
  | "cancelEdit"
  | "removeVenue";
type Action = { type: ActionTypes; payload: EventBuilderPayload };
type Dispatch = (action: Action) => void;
type EventImageType = {
  file: string | null;
  image: string | ArrayBuffer | null;
  blob: Blob | null;
};

export type BuilderPriceLevel = {
  name: string;
  description?: string;
  price_cents: number;
  season_pass_group_id?: string;
};

export type BuilderSection = {
  name: string;
  description?: string;
  price_levels: BuilderPriceLevel[];
};

export type BuilderTier = {
  name: string;
  description?: string;
  sections: BuilderSection[];
};

export type TierWizardState = {
  newTierName: string;
  newSectionName: string;
  newPriceLevelName: string;
  newPriceLevelPrice: number | null;
  sections: BuilderSection[];
  priceLevels: BuilderPriceLevel[];
};

export type EventBuilderState = {
  editingSectionIndex: number | undefined;
  editingTierIndex: number | undefined;
  activeStep: number | undefined;
  name: string;
  description: string;
  start: Date | string | null;
  end: Date | string | null;
  primaryVenue: Venue | null;
  image: EventImageType;
  tierWizard: TierWizardState;
  tiers: BuilderTier[];
};
export type EventBuilderPayload = Partial<EventBuilderState> &
  Partial<TierWizardState>;

const EventBuilderContext = React.createContext<{
  state: EventBuilderState;
  dispatch: Dispatch;
} | null>(null);

export function EventBuilderReducer(_: EventBuilderState, action: Action) {
  switch (action.type) {
    case "cancelEdit":
      return {
        ..._,
        tierWizard: {
          newTierName: "",
          newSectionName: "",
          newPriceLevelName: "",
          newPriceLevelPrice: 0,
          sections: [],
          priceLevels: [],
        },
        editingSectionIndex: undefined,
        editingTierIndex: undefined,
      };
    case "updateTierWizard":
      return {
        ..._,
        tierWizard: {
          ..._.tierWizard,
          ...action.payload,
        },
      };
    case "updateEvent":
      return {
        ..._,
        ...action.payload,
      };
    case "nextStep":
      return {
        ..._,
        activeStep: (_?.activeStep || 0) + 1,
      };
    case "prevStep":
      return {
        ..._,
        activeStep: (_?.activeStep || 0) - 1,
      };
    case "goToStep":
      return {
        ..._,
        activeStep: action.payload.activeStep,
      };
    case "editTier":
      return {
        ..._,
        editingTierIndex: action.payload.editingTierIndex,
      };
    case "editSection":
      return {
        ..._,
        editingSectionIndex: action.payload.editingSectionIndex,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
}

function EventBuilderProvider({ children }: { children: React.ReactNode }) {
  // const storedDefaultState = localStorage.getItem("eventBuilderState");

  const defaultState: EventBuilderState = {
    tierWizard: {
      newTierName: "",
      newSectionName: "",
      newPriceLevelName: "",
      newPriceLevelPrice: 0,
      sections: [],
      priceLevels: [],
    },
    description: "",
    editingSectionIndex: undefined,
    editingTierIndex: undefined,
    activeStep: 1,
    name: "",
    start: "",
    end: "",
    primaryVenue: null,
    image: { file: null, image: null, blob: null },
    tiers: [
      {
        name: "General Admission",
        description: "General admission entry.",
        sections: [
          {
            name: "GA",
            description: "General admission entry to the event.",
            price_levels: [
              {
                name: "Adult",
                description: "Adult ticket pricing.",
                price_cents: 5000,
              },
              {
                name: "Child",
                description: "Adult ticket pricing.",
                price_cents: 4000,
              },
              {
                name: "Military",
                description: "Adult ticket pricing.",
                price_cents: 4000,
              },
            ],
          },
        ],
      },
    ],
  };
  const [state, dispatch] = React.useReducer(EventBuilderReducer, defaultState);
  const [storedState, setStoredState] = useLocalStorage<EventBuilderState>(
    "eventBuilderState",
    state
  );
  const value = { state, dispatch };

  React.useEffect(() => {
    setStoredState(state);
  }, [state]);

  return (
    <EventBuilderContext.Provider value={value}>
      {children}
    </EventBuilderContext.Provider>
  );
}

function useEventBuilder() {
  const context = React.useContext(EventBuilderContext);

  if (!context) {
    throw new Error(
      "useEventBuilder must be used within an EventBuilderProvider"
    );
  }
  return context;
}

export { EventBuilderProvider, useEventBuilder };
