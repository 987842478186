import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { CategoryTotal } from "../../../types/Report";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Pie } from "react-chartjs-2";
import { Button } from "../../../components/reusable/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import { CategoryRevenueTable } from "./TaxReport";

export const SalesByCategory = () => {
  const { currentOrganization } = useVendor();
  const [searchParams, setSearchParams] = useState({
    fromDate: "",
    toDate: "",
  });
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["sales-by-category", currentOrganization, searchParams],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/sales_by_product_category`,
        params:
          searchParams.fromDate || searchParams.toDate
            ? {
                search: {
                  from_date: searchParams.fromDate,
                  to_date: searchParams.toDate,
                },
              }
            : {},
      }),
    enabled: !!currentOrganization,
  });

  const handleSearch = () => {
    setSearchParams({ fromDate, toDate });
  };

  const clearSearch = () => {
    setSearchParams({ fromDate: "", toDate: "" });
    setFromDate("");
    setToDate("");
  };

  useEffect(() => {
  }, [searchParams, refetch]);


  const categoryData: CategoryTotal[] = data?.data?.product_data ?? [];

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = categoryData.map((item: CategoryTotal) => item.product_category_name);
  

  const chartData = {
    labels,
    datasets: [
      {
        data: categoryData.map((item: CategoryTotal) => item.total_revenue_cents / 100),
        backgroundColor: [
          '#7209B7',
          '#486BFF',
          '#F72685',
          '#E4C4FA',
          '#9966FF',
          '#FF9F40',
        ],
      },
    ],
  };

  const downloadSalesByCategoryReport = () => {
    makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/sales_by_product_category.csv`,
      params: {
        organization_id: currentOrganization?.id
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `sales-by-category-${currentOrganization?.name}.csv`;
        saveAs(blob, filename);
      }
    });
  }

  return (
    <div className="mt-3">
            <VendorCard className="mt-3 pb-10 mb-5">
        <div className="flex items-end relative">
          {(fromDate || toDate) && (
            <span
              className="absolute text-sm text-pink top-full mt-2 z-10"
              onClick={clearSearch}
              role="button"
            >
              Clear Search
            </span>
          )}
          <div className="flex flex-col">
            <label htmlFor="fromDate" className="mr-2">
              From
            </label>
            <input
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="mr-4 rounded border-gray-400 h-10"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="toDate" className="mr-2">
              To
            </label>
            <input
              type="date"
              id="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="mr-4 rounded border-gray-400 h-10"
            />
          </div>

          <Button onClick={handleSearch} variant="purple">
            Search
          </Button>
        </div>
      </VendorCard>

      <>
        <VendorCard className="mt-3">
          <h2 className="text-lg font-bold pb-5" >Sales by Category</h2>
          <div className="w-96 h-96 mx-auto">
            <Pie data={chartData} options={chartOptions} />
          </div>
        </VendorCard>
        <VendorCard className="mt-3">
          <div className="flex">
            <Button onClick={downloadSalesByCategoryReport} size="sm" className="mb-2 ml-auto" >
              <ArrowDownTrayIcon className="w-5 h-5" />
              Download CSV
            </Button>
          </div>
          <CategoryRevenueTable categoryTotals={categoryData} />
        </VendorCard>
      </>
    </div>
  );
};
